
import codeLanding from "@/views/pages/Code.vue";

const codeRoutes = [
    {
      path: '/code',
      name: 'code-listing',
      component: codeLanding,
    }
  ];
  
  export default codeRoutes;